import Vue from 'vue';
import { BootstrapVue } from 'bootstrap-vue';
import App from './App.vue';
import VueTelInput from 'vue-tel-input';
import 'vue-tel-input/dist/vue-tel-input.css'
import router from './router';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';

Vue.use(VueTelInput)
Vue.use(BootstrapVue);

Vue.config.productionTip = false;

// const FUNCTIONS_ROOT_URL = 'http://localhost:5001/financial-forms/us-central1/api';
// const DATABASE_URL = 'https://obot-55a3f-6f99blead.firebaseio.com/';
const FUNCTIONS_ROOT_URL = 'https://us-central1-obot-55a3f.cloudfunctions.net/api';

new Vue({
  router,
  render: (h) => h(App),

  functionsRootUrl: FUNCTIONS_ROOT_URL,
  // databaseUrl: DATABASE_URL,
}).$mount('#app');
