import firebase from "firebase/app";
import "firebase/storage";
// get config from Firebase console
var firebaseConfig = {
  apiKey: "AIzaSyCiU--l03yJXflT1iTFTmm1fgFB5GooIY8",
  authDomain: "obot-55a3f.firebaseapp.com",
  databaseURL: "https://obot-55a3f.firebaseio.com",
  projectId: "obot-55a3f",
  storageBucket: "obot-55a3f.appspot.com",
  messagingSenderId: "386141269311",
  appId: "1:386141269311:web:bd30507041fd40c240378a",
  measurementId: "G-6BCJGKDEF7"
};

firebase.apps && !firebase.apps.length
  ? firebase.initializeApp(firebaseConfig)
  : "";

export const FirebaseStorage = firebase.storage();
export default firebase;
