import Vue from 'vue';
import VueRouter from 'vue-router';
import PasoLlenar from '@/views/PasoLlenarCampos.vue';
import PasoLlenar2 from '@/views/LlenarCamposCliente.vue';


Vue.use(VueRouter);

const routes = [
  {
    path: '/lotes',
    name: 'lotes',
    component: PasoLlenar,
    props: true,
  },
  {
    path: '/lotesactualizado',
    name: 'lotes2',
    component: PasoLlenar2,
    props: true,
  },
];

const router = new VueRouter({
  routes,
  scrollBehavior () {
    return { x: 0, y: 0 }
  }
});

export default router;
