<template>
  <div id="app" class="fondo">
    <p>En Breves se descargara el PDF actualizado</p>
  </div>
</template>

<script>
import firebase, { FirebaseStorage } from "@/services/firebase.js";
import download from 'downloadjs';
import { PDFDocument } from 'pdf-lib';

const axios = require('axios').default;

axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
axios.defaults.headers.post['Access-Control-Allow-Methods'] = 'GET, POST, OPTIONS';
axios.defaults.headers.post['Access-Control-Allow-Headers'] = 'Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers,X-Access-Token,XKey,Authorization';
axios.defaults.timeout = 20000;
export default {
  props: [
    'sessionId',
    'momentsSDK',
  ],
  data() {
    return {
      lote27: '',
      patriotActInfo: '',
      options: [
        { text: 'Lote29-Comprado', value: 'radio-29' },
        { text: 'Lote29-SinComprar', value: 'radio-29-No' },
      ],
      LoteCheckBox: '',
      myFile: null,
      processing: false,
      fileURL: null,
      totalFileCounter: 0,
      uploadedFileCounter: 0,
      idpedido: 0,
      cambiosPdf: null,
    };
  },
  async created() {
      var today = new Date();
      var dd = String(today.getDate()).padStart(2, '0');
      var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
      var yyyy = today.getFullYear();
      const url = 'https://balsayasociados.osombot.com/templates/La%20Juana%20PDF-compressed%20editado.pdf';
      const existingPdfBytes = await fetch(url).then((res) => res.arrayBuffer()); //, {mode: 'no-cors'}
      console.log(existingPdfBytes);
      const pdfDoc = await PDFDocument.load(existingPdfBytes);
      const form = pdfDoc.getForm();
      await axios.get("https://balsayasociados.wapchita.com/historiales/getLotesJson/1").then((response)=>{
        console.log(response.data);
        if (response.data.cambios === true) {
          for (let i = 0; i < response.data.lotes.length; i++) {
				    form.getCheckBox(response.data.lotes[i].lote).check()
            form.getTextField('lotestotal').setText(`${response.data.total}`);
			    }
          this.cambiosPdf = response.data.cambios
        } else {
          window.alert("No se detectaron cambios a pintar en el pdf")
        }
        
      }).catch((err)=>{console.log(err);})
      const pdfBytes = await pdfDoc.save();
      if (this.cambiosPdf === true) {
      /*  const imgData = new FormData();
          imgData.append("pdf", pdfBytes);
          const filePath = `PDF-Balsa/PDFBalsa-${dd}-${mm}-${yyyy}.pdf`;
          const metadata = { contentType: "application/pdf" };

          const uploadTask = FirebaseStorage.ref()
            .child(filePath)
            .put(pdfBytes, metadata);
          uploadTask.on(
            firebase.storage.TaskEvent.STATE_CHANGED,
            (snapshot) => {
              const progress =
                (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
              //console.log("Upload is " + progress + "% done");
            },
            (error) => {
              console.log(error);
            },
            async () => {
              const imgURL = await uploadTask.snapshot.ref.getDownloadURL();
              this.uploadedFileCounter++;
              console.log(this.uploadedFileCounter, this.totalFileCounter);
              if (this.uploadedFileCounter >= this.totalFileCounter) {
                //close moment
                //console.log("CLOSE moment");
                this.fileURL = imgURL;
                console.log(this.fileURL);
                // this.momentsSDK.sendSystemMessage({
                //   text: "Files are uploaded",
                //   recipients: "agents",
                // });
                //console.log("CLOSED moment");
                //this.momentsSDK.close();
              }
              console.log("uploaded image: " + imgURL);
              function utf8_to_b64(str) {
                return window.btoa(unescape(encodeURIComponent(str)));
              }
              var url64 = utf8_to_b64(imgURL)
              var url64v2 = btoa(imgURL)
                console.log(url64v2);
                console.log("URLV2");
                axios.post(`https://balsayasociados.wapchita.com/mensajes/sendFile/${url64v2}`)
		 			      .then((response2) => {
		 				    console.log(response2);
		 			      })
		 			      .catch((error) => {
                window.alert("error en el envio de whatsapp")
		 				    console.log(error);
		 			  });*/
              download(pdfBytes, `PDFBalsa-${dd}-${mm}-${yyyy}.pdf`, 'application/pdf');
         // })
          
      }
              
       

      // Trigger the browser to download the PDF document
  
  },
  methods: {
    async next() {
      
      const url = 'https://nefsgroup.com/templates/La%20Juana%20PDF%20limpioCampov2.pdf';
      const existingPdfBytes = await fetch(url).then((res) => res.arrayBuffer()); //, {mode: 'no-cors'}
      console.log(existingPdfBytes);
      const pdfDoc = await PDFDocument.load(existingPdfBytes);
      const form = pdfDoc.getForm();
      await axios.get("https://balsayasociados.wapchita.com/historiales/getLotesJson").then((response)=>{
        console.log(response.data);
        for (let i = 0; i < response.data.lotes.length; i++) {
				form.getCheckBox(response.data.lotes[i].lote).check()
			  }
        
      }).catch((err)=>{console.log(err);})
      const pdfBytes = await pdfDoc.save();
      console.log(pdfBytes);
      // var base64String = Buffer.from(pdfBytes).toString('base64');
    //  console.log(base64String);
      //btoa([].reduce.call(new Uint8Array(bufferArray),function(p,c){return p+String.fromCharCode(c)},''))
       //axios.post(
		 	//			"https://api.chat-api.com/instance182883/sendFile?token=08wm1u3d1m2icrce",
		 //				{
		 	//				chatId: `59893984460-1606743026@g.us`,
		// 					body: `${base64String}`,
		 	//				filename: `PDFBalsa.pdf-${dd}-${mm}-${yyyy}`,
		 //					caption: ``,
		 //				}
		//			)
		 //			.then((response2) => {
		 //				console.log(response2);
		 	//		})
		 //			.catch((error) => {
		 	//			console.log(error);
		 	//		});

      // Trigger the browser to download the PDF document
      download(pdfBytes, `PDFBalsa-${dd}-${mm}-${yyyy}.pdf`, 'application/pdf');
    },
  },
};
</script>

<style scoped lang="sass">
  .cardSubtitle
    padding: 0 10 0

  .buttonNavigation
    display: flex
    justify-content: flex-end

  .disp-none
    display: none !important

  #app
    height: 100%;
</style>
